import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import VideoPhoto from "../images/video-photo.jpg"
import Layout from "../components/layout"
import VideoContainer from "../components/VideoContainer"
import SEO from "../components/seo"
import { PageHeaderText, InfoContainerEven, InfoContainerOdd, Blue, Red, Info, InfoHeader, InfoInside } from '../components/common'

const TitleImage = styled(Grid)`
  height: 35vh;
  width: 100%;
  background: linear-gradient(
      180deg,
      rgba(10, 10, 10, 0.61) 35.42%,
      rgba(51, 121, 200, 0.85) 99.48%
    ),
    url(${VideoPhoto});
  background-size: cover;
`
const Placeholder = styled.div`
  height: 50vh;
  width: 60vw;
  background: grey;
  float: left;
  display: inline-block;
  vertical-align: middle;
  margin: auto 0;
  margin-bottom: 30px;
`
const InfoHeaderHolder = styled.div`
  width: 80%;
  height: 15vh;
  margin: 5vh auto 1vh auto;
`
const VideoInfoContainer = styled(Grid)`
  min-height: 38vh;
  width: 100%;
  margin: auto;
  display: flex;
  box-shadow: inset 4px 4px 7px rgba(0, 0, 0, 0.25);
`
const MoreInfo = styled(Grid)`
  color: #f2f2f2;
  height: 75px; 
  width: 100%;
  margin: auto;
  display: flex;
  background: #0d0d0d;
  padding-top: 10px;
  padding-left: 10px;
`
const StyledLink = styled.a`
  font-family: Raleway;
  text-align: right;
  font-size: 14px;
  font-weight: normal;

  &:active,
  &:link,
  &:hover,
  &:visited: {
    color: blue;
    text-decoration: none;
  }
`

export default ({ data }) => {
  const playlists = data.allYoutubePlaylist.nodes
  const kidsPlaylist = playlists.filter(
    playlist => playlist.title === "Childrens Events"
  )
  const kidsItems = kidsPlaylist[0].items
    .slice(0, 4)

  const sermonPlaylist = playlists.filter(
    playlist => playlist.title === "Sermons"
  )
  const sermonItems = sermonPlaylist[0].items
    .slice(0, 4)

  return (
    <Layout>
      <SEO title="Videos" />
      <TitleImage>
        <PageHeaderText>Videos</PageHeaderText>
      </TitleImage>
      <InfoContainerEven container>
        <InfoInside>
          <InfoHeader>
            <Blue>An Introduction to St.Alban's</Blue>
          </InfoHeader>
          <Info>Heres some information about this video</Info>
          <Placeholder></Placeholder>
        </InfoInside>
      </InfoContainerEven>
      <InfoContainerOdd container>
        <InfoHeaderHolder>
          <InfoHeader>
            <Blue>Kid's Videos</Blue>
          </InfoHeader>
          <Info>
            Videos that the Sunday school kids have created for different
            events.
          </Info>
        </InfoHeaderHolder>
        <VideoInfoContainer container>
          {kidsItems &&
            kidsItems.map(kidsPlaylistItem => (
              <VideoContainer videoData={kidsPlaylistItem} />
            ))}
        </VideoInfoContainer>
      </InfoContainerOdd>
      <MoreInfo item xs={12}>
        <StyledLink href={kidsPlaylist[0].slug}>
          More Videos<Red> {">"} </Red>
        </StyledLink>
      </MoreInfo>
      <InfoContainerEven container>
        <InfoHeaderHolder>
          <InfoHeader>
            <Blue>Sermons</Blue>
          </InfoHeader>
          <Info>
            These are some of the sermons from Fr. Steve Capitelli in case you
            missed a week or wish to see his style of sermons
          </Info>
        </InfoHeaderHolder>
        <VideoInfoContainer container>
          {sermonItems &&
            sermonItems.map(sermonItem => (
              <VideoContainer videoData={sermonItem} />
            ))}
        </VideoInfoContainer>
      </InfoContainerEven>
      <MoreInfo>
        <StyledLink href={sermonPlaylist[0].slug}>
          More Videos<Red> {">"} </Red>
        </StyledLink>
      </MoreInfo>
    </Layout>
  )
}

export const query = graphql`
  query videosQuery {
    allYoutubePlaylist(
      sort: { fields: items___videoPublishedAt, order: DESC }
    ) {
      nodes {
        id
        channelTitle
        description
        slug
        title
        items {
          id
          position
          title
          videoId
          videoPublishedAt
          description
        }
      }
    }
  }
`
